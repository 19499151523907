.timer-component {
  font-weight: bold;
  color: green;

  > span  {
    font-family: "Barlow Condensed", sans-serif;
    display: inline-block;
    margin-left: 10px;
    padding-right: 5px;
    font-size: 1.1rem;
    text-transform: uppercase;
    
    svg {
      vertical-align: bottom;
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .MuiAlert-root {
    padding: 0px 16px;
    margin: 0px 16px;
  }

  .ending {
    color: red;
  }
}

.left-align {
  text-align: left;
}

.option-list {
  margin-top: 16px;
  padding: 0;
}

.pay-link {
  font-family: "Barlow Condensed",sans-serif;
  color: #3f51b5;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  font-size: 18px;

  &:hover {
    background-color: rgba(63, 81, 181, 0.04);
  }
}