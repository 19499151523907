.loading-game-table {
  text-align: center;
}

.game-page-body {
  background-image: none;
  background-color: #fff;
}

.game-page {
  background: #000;
  color: #fff;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.game-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.game-table > header {
  padding: 5px;

  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .poker-rankings-btn {
    font-family: "Barlow Condensed", sans-serif;
    text-transform: uppercase;
    font-weight: bold;

    .MuiSvgIcon-root {
      width: .5em;
      height: .5em;
    }
  }
}

.table-btn-actions {
  height: auto;
  background: black;

  button {
    font-weight: bold;
    line-height: 1.3;

    &.MuiButton-contained.Mui-disabled {
      background: #8a8a8a;
      color: #d6d6d6;
    }
  }

  .call-amt-txt .chip-icon {
    width: .7em;
    height: .7em;
    vertical-align: middle;
  }

  .btn-raise,
  .btn-raise:hover{
    background: orange;

    &.raising {
      background:#eee;
    }
  }

  .MuiButton-root {
    padding: 10px 16px;
  }

  .MuiButton-label {
    font-size: 20px;
  }
}

.raise-actions {
  display: flex;
  width: 100%;
  background: #fff;

  button,
  button:hover {
    margin: 5px;
    background: #45c105;
    color: #fff;
    text-shadow: 0 0 5px #4c4c4c;
  }

  > div {
    flex-grow: 1;
  }
}

.raise-slider {
  width: 50%;
  box-sizing: border-box;
  padding: 0 1rem;
}

.raise-btns {
  display: flex;
  padding: .8rem 0;
  width: 50%;
  justify-content: space-between;
}

.raise-by-amt {
  max-width: 110px;

  .MuiButton-label > span {
    font-size: 1.4rem;
    margin-left: .2rem;
  }
  .raise-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: .3rem;
  }
}

.raise-all-in {
  max-width: 70px;
}

.game-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 500px;

  > header {
    color: #000;
    background: #fff;
  }
}

.winner-modal,
.next-round-modal {
  width: 70%;
  background: rgba(0,0,0, .6);
  padding: 10px;
}



// temp styles for updates on raise
.raise-all-in {
  max-width: none;
}

.raise-actions {
  display: block;
}

.raise-slider {
  width: 80%;
  margin: 0 2%;
}

.raise-input-manual {
  width: 14%;
  text-align: center;
  margin: 0 1% 0 0;
}

.raise-select-amount {
  display: flex;
  width: 100%;
  align-items: center;
}

.confirm-bet-btns {
  display: flex;
  justify-content: space-between;
}

.raise-input-txt {
  text-align: center;
}

.winner-modal-container,
.round-txt-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
}

.winner-modal {

  h2 {
    margin: 0 0 10px;
  }

  p {
    margin-bottom: 50px;
  }
}