.lobby {
  h1 {
    margin: 0;
  }

  ul {
    padding: 0;
  }

  .lobby-details {
    color: black;
  }
}


.game-details {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.ad-box {
  margin-bottom: 20px;

  &.state-farm {
    margin-bottom: 10px;
  
    img {
      width: 100%;
      max-width: 480px;
      display: block;
      margin: 0 auto;
    }
  }
}

.game-setup,
.lobby-players{
  background: #fff;
  padding: 20px;

  h3 {
    margin: 0 0 10px;
    font-size: 22px;
  }

  h4 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    margin: 0 0 4px;
    font-size: .9rem;
  }

  h4 {
    font-weight: normal;
  }
}

.game-setup  {
  padding-bottom: 0;
}

.lobby-players {
  margin-bottom: 20px;

  li {
    list-style: none;
    color: green;
    
    svg {
      vertical-align: middle;
    }
  }
}

.form-elem {
  display: block;
}

.waiting-for-host {
  margin: 0;
}

.start-game-timer {
  font-size: 14px;
  font-weight: normal;
}

.deck-preview {
  .card-select {
    width: 30%;
    box-sizing: border-box;
    margin-right: 10px;
    opacity: .25;
    display: inline-block;

    .card {
      width: 100%;
    }

    &.selected {
      opacity: 1;
    }
  }

  .card-big {
    border: 1px solid;
    border-radius: 5px;
  }
}