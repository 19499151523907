
.tier-pay-info {
  margin-bottom: 40px;
}

.page.tier {
  .MuiButton-contained.Mui-disabled {
    color: #7d7d7d;
    box-shadow: none;
    background-color: #eee;
  }
}