.faq-page {
  ul {
    padding: 0;

    li {
      list-style: none;
      padding: 0;
      margin-bottom: 30px;
  
      p {
        margin: 0 0 5px;
  
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}