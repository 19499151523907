
.aces {
  text-align: center;
  margin-top: 25px;
}

.aces svg {
  width: 20%;
}

.contact-content {
  text-align: center;
}