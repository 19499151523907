// TODO
.page {
  background: black;
  color: #fff;
}

div.content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
  font-family: 'Barlow', sans-serif;
}