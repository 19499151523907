.card-deck-previous {
  display: block;
  margin: 0 auto 10px;
  max-width: 500px;

  svg {
    width: 14%;
    background: #fff;
    border-radius: 5px;
  }
}

.home-page {
  h1 {
    margin: 0 0 15px;
  }

  p {
    font-size: 30px;
  }

  .content {
    text-align: center;
  }
}

.donate-container {
  margin-top: 16px;
}