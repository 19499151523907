.paypal-opts {
  width: 100%;
}

.total span {
  font-weight: normal;
}

.pay-page {
  padding: 0 16px;
  max-width: 750px;
  margin: auto;
  background-color: white;
}

.pay-form-control {
  text-align: center;
}

.pay-title, .pay-subtitle {
  padding: 4px 0;
}
