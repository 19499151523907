// Styles
.ad-page {
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9);
}

.game-loading-txt {
  font-family: "Barlow Condensed", sans-serif;
}