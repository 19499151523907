.chip-count {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.chip-icon {
  color: red;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid red;
  vertical-align: bottom;
}